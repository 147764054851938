/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Fade,
  Paper,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import Logo from "../assets/logo.png"; 

// Enhanced styled components
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: "rgba(255, 255, 255, 0.98)",
  backdropFilter: "blur(10px)",
  boxShadow: "0 2px 20px rgba(0, 0, 0, 0.06)",
  position: "fixed",
  transition: "all 0.3s ease",
}));

const LogoContainer = styled(Box)({
  height: "40px",
  cursor: "pointer",
  transition: "transform 0.3s ease",
  "&:hover": {
    transform: "scale(1.05)",
  },
});

const CategoryContainer = styled(Box)({
  display: "flex",
  gap: "1rem",
  alignItems: "center",
  "@media (max-width: 1024px)": {
    display: "none",
  },
});

const CategoryItem = styled(Typography)({
  padding: "8px 16px",
  cursor: "pointer",
  position: "relative",
  color: "#000",
  fontSize: "0.95rem",
  fontWeight: 500,
  transition: "all 0.3s ease",
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: "50%",
    width: 0,
    height: "2px",
    backgroundColor: "#f50057",
    transition: "all 0.3s ease",
    transform: "translateX(-50%)",
  },
  "&:hover": {
    color: "#f50057",
    "&::after": {
      width: "80%",
    },
  },
});

const MegaMenu = styled(Paper)({
  position: "absolute",
  top: "100%",
  left: 0,
  right: 0,
  backgroundColor: "rgba(255, 255, 255, 0.98)",
  backdropFilter: "blur(10px)",
  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  padding: "2rem",
  display: "none",
  zIndex: 1000,
});

const CategoryGroup = styled(Box)({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
  gap: "1.5rem",
  maxWidth: "1200px",
  margin: "0 auto",
});

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showMegaMenu, setShowMegaMenu] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const StyledDrawer = styled(Drawer)({
    "& .MuiDrawer-paper": {
      backgroundColor: "#000",
      color: "#fff",
      width: 300,
      padding: "2rem 1rem",
    },
  });

  return (
    <>
      <StyledAppBar 
        elevation={scrolled ? 4 : 0}
        style={{ 
          height: scrolled ? "70px" : "80px",
          backgroundColor: scrolled ? "rgba(255, 255, 255, 0.98)" : "rgba(255, 255, 255, 0.95)"
        }}
      >
        <Toolbar style={{ height: "100%" }}>
          <LogoContainer>
            <img src={Logo} alt="Yemidyn Logo" style={{ height: "100%" }} />
          </LogoContainer>

  
            
          <Box sx={{ ml: "auto" }}>
            <IconButton 
              color="inherit" 
              onClick={() => setDrawerOpen(true)}
              sx={{ 
                color: "#000",
                "&:hover": {
                  backgroundColor: "rgba(0,0,0,0.04)",
                },
              }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </StyledAppBar>

      <StyledDrawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 3 }}>
          <IconButton 
            onClick={() => setDrawerOpen(false)}
            sx={{ color: "#fff" }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <List>
          {["About Us", "Our Products", "Testimonials"].map((text) => (
            <ListItem 
              key={text}
              button 
              component="a"
              href={`#${text.toLowerCase().replace(" ", "-")}`}
              sx={{
                py: 2,
                "&:hover": {
                  backgroundColor: "rgba(255,255,255,0.1)",
                },
              }}
            >
              <ListItemText 
                primary={text}
                primaryTypographyProps={{
                  sx: { 
                    fontWeight: 500,
                    letterSpacing: "0.5px",
                  }
                }}
              />
            </ListItem>
          ))}
          <ListItem 
            button 
            component="a"
            href="https://wa.me/c/2348169469300"
            sx={{
              py: 2,
              mt: 2,
              backgroundColor: "#f50057",
              borderRadius: "8px",
              "&:hover": {
                backgroundColor: "#c51162",
              },
            }}
          >
            <ListItemText 
              primary="Order Now"
              primaryTypographyProps={{
                sx: { 
                  fontWeight: 600,
                  textAlign: "center",
                }
              }}
            />
          </ListItem>
        </List>
      </StyledDrawer>
    </>
  );
};

export default Header;