import React from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import AboutUs from '../components/AboutUs';
import ProductShowcase from '../components/ProductShowcase';
import Testimonials from '../components/Testimonials';
import Footer from '../components/Footer';
import LatestCollectionsSection from '../components/LatestCollectionsSection';


const theme = createTheme({
  palette: {
    primary: {
      main: '#7c4dff',
    },
    secondary: {
      main: '#448aff',
    },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    h3: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 500,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '50px',
          textTransform: 'none',
          padding: '8px 24px',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          fontWeight: 500,
        },
      },
    },
  },
});



const Home = () => {
  return (
    <div>
        <Header />
        <HeroSection />
        <AboutUs />
        <ThemeProvider theme={theme}>
        <ProductShowcase />
        </ThemeProvider>
        <LatestCollectionsSection />
        <Testimonials />
        <Footer />
    </div>
  );

}

export default Home;