/* eslint-disable no-unused-vars */
// ProductShowcase.jsx
import React, { useState } from 'react';
import { 
  Typography, 
  Box, 
  Container, 
  Grid, 
  Card, 
  CardMedia,
  Button,
  Chip,
  IconButton,
  styled,
  useTheme
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { 
  LocalShipping as ShippingIcon,
  Security as SecurityIcon,
  Loop as ReturnIcon,
  Star as StarIcon,
  ShoppingCart as CartIcon,
  Add as PlusIcon,
  Favorite as HeartIcon,
  ChevronRight as ChevronRightIcon
} from '@mui/icons-material';

// Import all images
import caps from "../assets/products/caps.jpg";
import hoodies from "../assets/products/hoodies.jpg";
import tshirts from "../assets/products/tshirts.jpg";
import sweatbands from "../assets/products/sweatbands.jpg";
import socks from "../assets/products/socks.jpg";
import comingSoon from "../assets/products/coming-soon.jpg";
import jeans from "../assets/latest/classic-jeans.jpeg";
import ladies from "../assets/categories/ladies/shortSkirt.jpeg";
import croptee from "../assets/categories/croptee/cropTeeCosmos.jpeg";
import hat from "../assets/categories/hat/Hat.jpeg";
import singlet from "../assets/categories/singlet/singlet.jpeg";

// Styled components
const ProductCard = styled(Card)(({ theme }) => ({
  height: 500,
  position: 'relative',
  overflow: 'hidden',
  borderRadius: theme.spacing(2),
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: theme.shadows[8],
  }
}));

const ProductImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  transition: 'transform 0.5s ease',
});

const ProductOverlay = styled(Box)(({ theme, show }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  padding: theme.spacing(3),
  opacity: show ? 1 : 0,
  transition: 'opacity 0.3s ease',
  cursor: 'pointer',
}));

const CategoryBadge = styled(Chip)(({ theme, variant }) => ({
  backgroundColor: variant === 'primary' ? theme.palette.primary.main : 'rgba(255, 255, 255, 0.9)',
  color: variant === 'primary' ? 'white' : 'black',
  margin: theme.spacing(0.5),
  '&:hover': {
    backgroundColor: variant === 'primary' ? theme.palette.primary.dark : 'rgba(255, 255, 255, 1)',
  }
}));

const FeatureCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  height: '100%',
  textAlign: 'center',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: theme.shadows[4],
  }
}));

const ColorCircle = styled(Box)(({ color, delay }) => ({
  position: 'absolute',
  width: 60,
  height: 60,
  borderRadius: '50%',
  backgroundColor: color,
  opacity: 0.5,
  animation: `ping 1.5s cubic-bezier(0, 0, 0.2, 1) infinite ${delay}s`,
  '@keyframes ping': {
    '75%, 100%': {
      transform: 'scale(2)',
      opacity: 0,
    },
  },
}));

const ProductShowcase = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [activeProduct, setActiveProduct] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('All');

  const products = [
    { 
      id: 1, 
      title: "Caps",
      image: caps,
      category: "Accessories",
      price: "₦15,000",
      tag: "Bestseller",
      colors: ["#FF6B6B", "#4ECDC4", "#45B7D1"],
      path: "caps"
    },
    { 
      id: 2, 
      title: "Hoodies",
      image: hoodies,
      category: "Outerwear",
      price: "₦25,000",
      tag: "New",
      colors: ["#96CEB4", "#FFEEAD", "#D4A5A5"],
      path: "hoodies"
    },
    { 
      id: 3, 
      title: "T-Shirts",
      image: tshirts,
      category: "Basics",
      price: "₦12,000",
      tag: "Popular",
      colors: ["#9B59B6", "#3498DB", "#2ECC71"],
      path: "t-shirts"
    },
    {
      id: 4,
      title: "Sweatbands",
      image: sweatbands,
      category: "Accessories",
      price: "₦5,000",
      tag: "New",
      colors: ["#E74C3C", "#F1C40F", "#2980B9"],
      path: "sweatbands"
    },
    {
      id: 5,
      title: "Socks",
      image: socks,
      category: "Accessories",
      price: "₦3,000",
      tag: "Essential",
      colors: ["#1ABC9C", "#D35400", "#8E44AD"],
      path: "socks"
    },
    {
      id: 6,
      title: "Sweatshirts",
      image: comingSoon,
      category: "Outerwear",
      price: "₦20,000",
      tag: "Coming Soon",
      colors: ["#27AE60", "#E67E22", "#2C3E50"],
      path: "sweatshirts"
    },
    {
      id: 7,
      title: "Jeans",
      image: jeans,
      category: "Bottoms",
      price: "₦18,000",
      tag: "Classic",
      colors: ["#3498DB", "#E74C3C", "#F39C12"],
      path: "jeans"
    },
    {
      id: 8,
      title: "Ladies-wears",
      image: ladies,
      category: "Women",
      price: "₦22,000",
      tag: "Featured",
      colors: ["#9B59B6", "#16A085", "#D35400"],
      path: "ladies-wears"
    },
    {
      id: 9,
      title: "Crop-Tee",
      image: croptee,
      category: "Women",
      price: "₦8,000",
      tag: "Trending",
      colors: ["#2ECC71", "#E67E22", "#8E44AD"],
      path: "crop-tee"
    },
    {
      id: 10,
      title: "Rugged-Hat",
      image: hat,
      category: "Accessories",
      price: "₦10,000",
      tag: "Durable",
      colors: ["#F1C40F", "#2980B9", "#C0392B"],
      path: "rugged-hat"
    },
    {
      id: 11,
      title: "Singlet",
      image: singlet,
      category: "Basics",
      price: "₦6,000",
      tag: "Essential",
      colors: ["#16A085", "#8E44AD", "#D35400"],
      path: "singlet"
    }
  ];

  const features = [
    {
      icon: ShippingIcon,
      title: "Express Delivery",
      description: "Free shipping on orders above ₦50,000"
    },
    {
      icon: SecurityIcon,
      title: "Secure Shopping",
      description: "100% secure payment processing"
    },
    {
      icon: ReturnIcon,
      title: "Easy Returns",
      description: "30-day hassle-free returns"
    },
    {
      icon: StarIcon,
      title: "Quality Products",
      description: "Handpicked premium items"
    }
  ];

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const filteredProducts = selectedCategory === 'All'
    ? products
    : products.filter(product => product.category === selectedCategory);

  const handleProductClick = (path) => {
    navigate(`/${path}`);
  };

  return (
    <div
      id="shop-now"
      style={{ padding: "2rem 0", backgroundColor: "#f4f4f4" }}
    >
    <Box 
      sx={{ 
        minHeight: '100vh',
        bgcolor: '#f5f5f5',
        py: { xs: 4, md: 8 },
        px: { xs: 2, md: 0 }
      }}
      id="our-products"
    >
      <Container maxWidth="lg">
        {/* Header Section */}
        <Typography 
          variant="h4" 
          component="h2" 
          align="center" 
          sx={{ 
            mb: 6,
            fontWeight: 800,
            textTransform: 'uppercase',
            letterSpacing: '0.05em'
          }}
        >
          Shop by Category
        </Typography>

        {/* Category Filter */}
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, justifyContent: 'center', mb: 4 }}>
          {['All', 'Accessories', 'Outerwear', 'Basics', 'Women', 'Bottoms'].map((category) => (
            <Chip
              key={category}
              label={category}
              onClick={() => handleCategoryChange(category)}
              color={selectedCategory === category ? 'primary' : 'default'}
              sx={{
                px: 2,
                '&:hover': {
                  backgroundColor: selectedCategory === category 
                    ? theme.palette.primary.dark 
                    : theme.palette.grey[300],
                }
              }}
            />
          ))}
        </Box>

        {/* Product Grid */}
        <Grid container spacing={4} sx={{ mb: 12 }}>
          {filteredProducts.map((product) => (
            <Grid item xs={12} sm={6} md={4} key={product.id}>
              <ProductCard
                onMouseEnter={() => setActiveProduct(product.id)}
                onMouseLeave={() => setActiveProduct(null)}
                onClick={() => handleProductClick(product.path)}
              >
                <Box sx={{ position: 'relative', height: '100%' }}>
                  <ProductImage
                    src={product.image}
                    alt={product.title}
                  />
                  
                  <Box sx={{ position: 'absolute', top: 16, left: 16, zIndex: 2 }}>
                    <CategoryBadge label={product.category} />
                    <CategoryBadge label={product.tag} variant="primary" />
                  </Box>

                  {activeProduct === product.id && (
                    <>
                      {product.colors.map((color, index) => (
                        <ColorCircle
                          key={color}
                          color={color}
                          delay={index * 0.2}
                          sx={{
                            top: `${20 + index * 30}%`,
                            left: `${20 + index * 30}%`,
                          }}
                        />
                      ))}
                    </>
                  )}

                  <ProductOverlay show={activeProduct === product.id}>
                    <Typography 
                      variant="h5" 
                      sx={{ 
                        color: 'white',
                        mb: 2,
                        fontWeight: 600,
                        textTransform: 'uppercase',
                        letterSpacing: '0.1em'
                      }}
                    >
                      {product.title}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <Button
                        variant="contained"
                        startIcon={<CartIcon />}
                        sx={{
                          bgcolor: 'white',
                          color: 'text.primary',
                          borderRadius: 25,
                          '&:hover': {
                            bgcolor: 'grey.100',
                            transform: 'scale(1.05)',
                          }
                        }}
                      >
                        Shop Now
                      </Button>
                
                    </Box>
                  </ProductOverlay>
                </Box>
              </ProductCard>
            </Grid>
          ))}
        </Grid>

        {/* Features Section */}
        <Grid container spacing={4}>
          {features.map((feature) => {
            const Icon = feature.icon;
            return (
              <Grid item xs={12} sm={6} md={3} key={feature.title}>
                <FeatureCard elevation={1}>
                  <Icon 
                    sx={{ 
                      fontSize: 48,
                      color: 'primary.main',
                      mb: 2 
                    }}
                  />
                  <Typography 
                    variant="h6" 
                    sx={{ 
                      mb: 1,
                      fontWeight: 600,
                      letterSpacing: '0.05em'
                    }}
                  >
                    {feature.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {feature.description}
                  </Typography>
                </FeatureCard>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Box>
    </div>
  );
};

export default ProductShowcase;