import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Caps from "./pages/Caps";
import Hoodies from "./pages/Hoodies";
import Socks from "./pages/Socks";
import Tshirts from "./pages/Tshirts";
import SweatBands from "./pages/SweatBands";
import Comingsoon from "./pages/Comingsoon";


// New pages
import Jeans from "./pages/Jeans";
import Ladies from "./pages/Ladies";
import CropTee from "./pages/CropTee";
import RuggedBucketHat from "./pages/RuggedBucketHat";
import Singlet from "./pages/Singlet";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/caps" element={<Caps />} />
        <Route path="/hoodies" element={<Hoodies />} />
        <Route path="/t-shirts" element={<Tshirts />} />
        <Route path="/sweatbands" element={<SweatBands />} />
        <Route path="/socks" element={<Socks />} />
        <Route path="/sweatshirts" element={<Comingsoon />} />
        
        {/* New routes */}
        <Route path="/jeans" element={<Jeans />} />
        <Route path="/ladies-wears" element={<Ladies />} />
        <Route path="/crop-tee" element={<CropTee />} />
        <Route path="/rugged-hat" element={<RuggedBucketHat />} />
        <Route path="/singlet" element={<Singlet />} />
      </Routes>
    </Router>
  );
};

export default App;
